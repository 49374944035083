import gql from 'graphql-tag';

export const CREATE_STORY = gql`
mutation createStory($postTitle: String!, $postContent: String!, $notifyMe: Boolean) {
  createStory(postTitle: $postTitle, postContent: $postContent, notifyMe: $notifyMe)
}
`;

export const CREATE_RECIPE = gql`
mutation createRecipe(
  $postTitle: String!,
  $description: String,
  $directions: String,
  $ingredients: String,
  $numOfServings: String,
  $notifyMe: Boolean
) {
  createRecipe(
    postTitle: $postTitle,
    description: $description,
    directions: $directions,
    ingredients: $ingredients,
    numOfServings: $numOfServings,
    notifyMe: $notifyMe
  )
}
`;

export const REINDEX_POST = gql`
mutation reindexPost($postId: NexusId!, $prefix: String) {
  reindexPost(postId: $postId, prefix: $prefix)
}
`;
