<template>
  <div v-if="isAdmin">
    <span v-if="isReindexing" style="font-style: italic;">
      reindex in progress...
    </span>
    <a
      v-else
      :href="'#'"
      @click.prevent="reindex"
    >
      Reindex This
    </a>
    <br>
  </div>

</template>

<script>

import { mapGetters } from 'vuex';
import { REINDEX_POST } from '@/graphql/mutations/post-mutations';

export default {
  name: 'ReindexThisLink',
  props: {
    postId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isReindexing: false,
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  methods: {
    reindex() {
      this.isReindexing = true;

      this.$apollo.mutate({
        mutation: REINDEX_POST,
        variables: {
          postId: this.postId,
          prefix: this.$route.query?.original_site || null,
        },
      }).then(({ data: { reindexPost } }) => {
        if (reindexPost) {
          this.$store.dispatch('addToastNotification', {
            toastType: 'success',
            description: 'Post reindexed succesfully',
          });
        } else {
          this.$store.dispatch('addGenericErrorNotification');
        }
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      }).finally(() => {
        this.isReindexing = false;
      });
    },
  },
};
</script>

<docs>
Link for admins to reindex a single post in ElasticSearch.
Styled as a link to match the EditThisLink component.
</docs>
