<template>
  <a v-if="isAdmin" :href="editUrl">Edit This</a>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EditThisLink',
  props: {
    postId: {
      type: String,
      required: true,
    },
    host: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['isAdmin']),
    editUrl() {
      const host = this.host ? `https://${this.host}` : '';
      return `${host}/wp-admin/post.php?post=${this.postId}&action=edit`;
    },
  },
};
</script>

<docs>
A component used to link admins to the edit view for a post in wordpress.
</docs>
