import gql from 'graphql-tag';
import { COMMENT_FRAGMENT } from './comment-queries';

const postSeoFields = `
canonical
metaRobotsNofollow
metaRobotsNoindex
metadesc
metakeywords
opengraphDescription
opengraphImage
opengraphTitle
title
twitterDescription
twitterTitle
`;

// Any changes to the arguments for this query must be reflected in COMMENT_UPDATE_QUERY
// If these do not match then cache write will fail when posting a new comment.
export const GET_SINGLE_POST_QUERY = gql`
  ${COMMENT_FRAGMENT}
  query post($slug: String, $id: NexusId, $category: String, $types: [String], $isPreview: Boolean, $originalSite: String) {
    post(slug: $slug, id: $id, category: $category, types: $types, isPreview: $isPreview, originalSite: $originalSite) {
      id
      originalSite
      crossPublishedSites
      title
      categories {
        name
        slug
      }
      content
      excerpt
      date
      modified
      type
      permalink
      commentStatus
      commentCount
      comments {
        ...CommentFragment
        comments {
          ...CommentFragment
        }
      }
      tags {
        name
      }
      author {
        id
        username
        avatar {
          thumb
          full
        }
      }
      featuredMedia {
        path
        url
        name
        alt
        urlMedium
        urlLarge
        urlRecommended
        huIllustratorName
      }
      hideDefaultSponsorDisclaimer
      showContributorDisclaimer
      showTreatmentDisclaimer
      reviewedby
      references
      seo { ${postSeoFields} }
      headTagAttrs
      footContent
      nutrition {
        calories
        caloriesFromFat
        carbohydrate
        carbohydrates
        cholesterol
        credits
        dietaryFiber
        fat
        fiber
        monounsaturatedFat
        potassium
        protein
        saturatedFat
        serving
        sodium
        sugar
        sugars
        totalCarbohydrates
        totalFat
        transFat
        unsaturatedFat
      }
      dataLayer
      isQuizResult
      password
      status
      metas
    }
  }
`;

export const GET_HOMEPAGE_QUERY = gql`
  query homepage($isPreview: Boolean, $id: NexusId) {
    homepage(isPreview: $isPreview, id: $id) {
      id
      date
      modified
      title
      content
      tags {
        name
      }
      dataLayer
      seo { ${postSeoFields} }
    }
  }
`;
