import gql from 'graphql-tag';

export const COMMENT_FRAGMENT = gql`
fragment CommentFragment on Comment {
  body
  id
  insertedAt
  parentCommentId
  user {
    id
    username
    insertedAt
    roleId
    badge
    avatar {
      thumb
    }
  }
  comments {
    id
    body
    insertedAt
    user {
      id
      username
      insertedAt
      roleId
      avatar {
        thumb
      }
    }
  }
}`;

// Query against cache when updating following ADD_COMMENT mutation
// The query signature MUST match GET_SINGLE_POST_QUERY
export const COMMENT_UPDATE_QUERY = gql`
${COMMENT_FRAGMENT}
query post($slug: String, $id: NexusId, $category: String, $types: [String], $isPreview: Boolean) {
  post(slug: $slug, id: $id, category: $category, types: $types, isPreview: $isPreview) {
    id
    comments {
      ...CommentFragment
      comments {
        ...CommentFragment
      }
    }
  }
}
`;
