<template>
  <full-bleed-layout class="layout--home">
    <site-masthead is-h1 may-have-sub-text class="masthead--home" />
    <loading-spinner v-if="loading !== 0" />
    <div v-else-if="hasHomepage">
      <content-renderer :sections="homepageContentSections" />
      <edit-this-link :post-id="homepage.id" />
      <br>
      <reindex-this-link :post-id="homepage.id" />
    </div>
  </full-bleed-layout>
</template>

<script>
import SiteMasthead from '@/components/Footer/SiteMasthead.vue';
import { GET_HOMEPAGE_QUERY } from '@/graphql/queries/post-queries';
import EditThisLink from '@/components/Posts/EditThisLink.vue';
import ReindexThisLink from '@/components/Posts/ReindexThisLink.vue';
import { homeJsonLd } from '@/utils/structured-data';

export default {
  name: 'HomeComponent',
  components: {
    SiteMasthead,
    EditThisLink,
    ReindexThisLink,
  },
  data() {
    return {
      loading: 0,
      homepage: {},
    };
  },
  metaInfo() {
    let metaObject = {};
    if (!this.loading && this.hasHomepage) {
      metaObject = {
        title: this.$root.$options.filters.decodeHtml(this.homepage.seo.title),
        titleTemplate: '%s',
        meta: [
          { vmid: 'description', name: 'description', content: this.homepage.seo.metadesc },
          {
            vmid: 'og:description',
            property: 'og:description',
            content: this.homepage.seo.opengraphDescription,
          },
          {
            vmid: 'twitter:description',
            property: 'twitter:description',
            content: this.homepage.seo.twitterDescription,
          },
          { vmid: 'og:image', property: 'og:image', content: this.homepage.seo.opengraphImage },
        ],
        link: [],
      };
    }
    return metaObject;
  },
  computed: {
    hasHomepage() {
      return this.homepage && Object.keys(this.homepage).length > 0;
    },
    homepageContentSections() {
      if (!this.homepage.content) { return []; }
      return JSON.parse(this.homepage.content).sections;
    },
  },
  watch: {
    // This is necessary as the apollo result callback will run twice on SSR,
    // possibly due to cache-and-network policy
    homepage(to) {
      if (!to) { return; }
      this.onLoad();
    },
  },
  methods: {
    setJsonLd() {
      this.$store.dispatch('setContentJsonLd', homeJsonLd(
        this.homepage,
        this.$root.hostname,
        this.$site.settings.condition_name,
      ));
    },
    onLoad() {
      this.setJsonLd();
      this.$store.dispatch('updateGlobalContext', {
        page: {
          type: 'homepage',
          id: this.homepage.dataLayer.post_id,
        },
      });

      this.$emit('loaded', this.homepage.dataLayer);
    },
  },
  apollo: {
    homepage: {
      query: GET_HOMEPAGE_QUERY,
      variables() {
        if (this.$route.query.preview) {
          return {
            isPreview: true,
            id: this.$route.query.preview_id,
            slug: this.$route.params.slug || null,
          };
        }
        return {};
      },
      result({ data }) {
        if (!data) { return; }
        if (!process.browser) {
          // make sure that we set the jsonLD
          // for the page, during SSR
          this.setJsonLd();
        }
      },
    },
  },
};
</script>
